import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CellContext } from "@tanstack/react-table"
import { Dropdown } from "components/dropdown"
import Tooltip from "components/tooltip"
import { useJwt } from "hooks/useJwt"
import DotsIcon from "icons/DotsIcon"
import { useAtom } from "jotai"

import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { getDocumentStatus } from "utils/getDocumentStatus"
import PadlockIcon from "icons/PadlockIcon"
import { isEditTextAtom } from "./ColumnTitle/atoms"
import { TooltipPosition } from "components/tooltip/enums"
import { showToast } from "components/toast/functions"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import useUploadedDocumentAPI from "hooks/useUploadedDocumentAPI"
import ModelIcon from "icons/ModelIcon"
import { ROUTES } from "routes"
import { useNavigate } from "react-router-dom"
import useFreePlan from "hooks/useFreePlan"
import { plansModalAtom } from "pages/BasePage/atoms"

interface ColumnActionsProps {
	row: CellContext<
		RetrieveLegalDocumentResponse,
		RetrieveLegalDocumentResponse
	>
	setOpen: (open: boolean) => void
	setDocumentId: (id: string) => void
	setTitle: (title: string) => void
}

export default function NewColumnActions({
	row,
	setOpen,
	setDocumentId,
	setTitle,
}: ColumnActionsProps) {
	const { downloadFile, createLegalDocument, copyLegalDocument } =
		useLegalDocumentAPI()
	const { copyUploadedDocument } = useUploadedDocumentAPI()
	const [jwt] = useJwt()
	const [, setIsEditText] = useAtom(isEditTextAtom)
	const isFreeUser = useFreePlan()
	const [, setIsOpen] = useAtom(plansModalAtom)

	const queryClient = useQueryClient()

	const navigate = useNavigate()
	const rowValue = row.getValue()
	const createLegalDocumentMutation = useMutation({
		mutationFn: () =>
			createLegalDocument({
				template_id: rowValue?.id,
				template_type: "DOCUMENT",
				title: ``,
				legal_document_type: rowValue.legal_document_type || "TEMPLATE",
			}),
		onSuccess: (data) => {
			navigate(ROUTES.documentDetail({ id: data.id }), {
				state: { documentAsModel: true },
			})
		},
	})

	const copyLegalDocumentMutation = useMutation({
		mutationFn: copyLegalDocument,
		onSuccess(data) {
			onSucessCopyDocument(data)
		},
	})

	const copyUploadedDocumentMutation = useMutation({
		mutationFn: copyUploadedDocument,
		onSuccess(data) {
			onSucessCopyDocument(data)
		},
	})

	function onSucessCopyDocument(data: RetrieveLegalDocumentResponse) {
		showToast(`O documento “${row.row.original.title}” foi duplicado.`)
		queryClient.setQueryData(
			["listLegalDocuments", jwt],
			(oldDocuments: RetrieveLegalDocumentResponse[] | undefined) => {
				if (oldDocuments) {
					return [data, ...oldDocuments]
				}

				return oldDocuments
			},
		)
		queryClient.invalidateQueries(["listLegalDocuments", jwt])
		queryClient.invalidateQueries({
			queryKey: ["current-user", jwt],
		})
	}

	const downloadLegalDocumentMutation = useMutation({
		mutationFn: downloadFile,
	})

	async function handleDownload(type: string) {
		await downloadLegalDocumentMutation.mutateAsync({
			id: row.row.original.id,
			type: type,
			name: row.row.original.title || "",
		})
	}

	// const downloadSections = [
	// 	{
	// 		items: [
	// 			{
	// 				label: "Download PDF",
	// 				icon: <PdfIcon className="fill-current" />,
	// 				onClick: () => {
	// 					handleDownload("pdf")
	// 				},
	// 			},
	// 			{
	// 				label: "Download Word",
	// 				icon: <WordIcon className="fill-current" />,
	// 				onClick: () => {
	// 					handleDownload("word")
	// 				},
	// 			},
	// 		],
	// 	},
	// ]

	function onCopyDocument() {
		const rowValue = row.getValue()

		if (
			typeof rowValue === "object" &&
			rowValue !== null &&
			"id" in rowValue
		) {
			const { id } = rowValue

			const type = row.row.original.type

			if (type === "uploaded_document") {
				copyUploadedDocumentMutation.mutateAsync(id)
			} else {
				copyLegalDocumentMutation.mutateAsync(id)
			}
		}
	}

	function onCreateModel() {
		if (isFreeUser) {
			setIsOpen(true)
			return
		}
		if (!isDowloadDisabled) {
			createLegalDocumentMutation.mutateAsync()
		}
	}

	const isDowloadDisabled =
		getDocumentStatus(row.row.original) !== "completed"

	const sections = [
		{
			items: [
				{
					label: (
						<span
							className={
								isDowloadDisabled ? `text-brand-gray-2` : ""
							}
						>
							Baixar
						</span>
					),
					onClick: () => {
						if (!isDowloadDisabled) handleDownload("docx")
					},
				},
				{
					label: "Renomear",
					onClick: () => {
						setIsEditText(row.row.original.id)
					},
				},
				{
					disabled:
						getDocumentStatus(row.row.original) === "processing",
					label: "Duplicar",
					onClick: () => {
						onCopyDocument()
					},
				},
				{
					label: "Excluir",
					deleteLabel: true,
					onClick: () => {
						setOpen(true)
						const rowValue = row.getValue() as
							| RetrieveLegalDocumentResponse
							| undefined
						if (
							rowValue &&
							"id" in rowValue &&
							"title" in rowValue
						) {
							setDocumentId(rowValue.id)
							setTitle(rowValue?.title || "")
						} else {
							console.error("Invalid row value:", rowValue)
							setDocumentId("")
							setTitle("")
						}
					},
				},
			],
		},
	]

	return (
		<div className="flex justify-end gap-4">
			<Tooltip text="Usar como modelo" position={TooltipPosition.Above}>
				<div
					className={` cursor-pointer ${
						isDowloadDisabled
							? "text-brand-gray-2"
							: "text-dark-blue-1 hover:text-dark-blue-2"
					}`}
					onClick={() => {
						onCreateModel()
					}}
				>
					<ModelIcon className="fill-current" />
				</div>
			</Tooltip>
			<Tooltip text="Privado" position={TooltipPosition.Above}>
				<div className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer">
					<PadlockIcon className="fill-current" />
				</div>
			</Tooltip>

			<Dropdown sections={sections} className="right-0 w-[165px]">
				<div className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer">
					<DotsIcon />
				</div>
			</Dropdown>
		</div>
	)
}
