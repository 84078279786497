import React, { useState, useRef } from "react"
import UpgradeButton from "components/upgrade-button"
import useCurrentUser from "hooks/useCurrentUser"
import CreditIcon from "icons/CreditIcon"
import CreditIcon2 from "icons/CreditIcon2"
import { CurrentUser } from "types/currentUser"
import useFreePlan from "hooks/useFreePlan"
import PlanName from "components/plan-name"

const CreditsInfo = ({
	show,
	productName,
	user,
	containerRef,
	isFreePlan,
	onMouseEnter,
	onMouseLeave,
	isProfissionalPlan,
}: {
	show: boolean
	productName?: string
	user?: CurrentUser
	containerRef: React.RefObject<HTMLDivElement>
	isFreePlan: boolean
	onMouseEnter: () => void
	onMouseLeave: (event: React.MouseEvent) => void
	isProfissionalPlan: boolean
}) => {
	return (
		<div
			className={`w-fit h-fit bg-brand-white-2 fixed bottom-[150px] left-[16px] rounded shadow-outlined-hover z-50 p-4 ${
				show ? "block" : "hidden"
			}`}
			ref={containerRef}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<PlanName name={productName || ""} />

			<div className="flex gap-2 items-center mt-4">
				<div>
					<CreditIcon2 size="20px" />
				</div>
				<p className="leading-5 text-cta-2">
					<span className="font-semibold text-dark-blue-1 ">
						{user?.credits_remaining
							? Math.ceil(user.credits_remaining)
							: 0}
						/{user?.subscription?.credits}
					</span>{" "}
					créditos disponíveis
				</p>
			</div>

			{user?.extra_credits && user?.extra_credits > 0 ? (
				<div className="flex gap-2 items-center mt-4 text-cta-2">
					<CreditIcon2 size="20px" />
					<p>
						<span className="font-semibold text-dark-blue-1">
							{Math.ceil(user.extra_credits)}
						</span>{" "}
						créditos extras
					</p>
				</div>
			) : null}
			{!isProfissionalPlan && (
				<UpgradeButton
					isBig
					className={"mt-8 w-full justify-center h-[38px]"}
					text={isFreePlan ? "Assinar agora" : "Fazer upgrade"}
				/>
			)}
		</div>
	)
}

const NewCredit = () => {
	const [isHovered, setIsHovered] = useState(false)

	const user = useCurrentUser()
	const productName = user?.subscription?.product_name
	const isFreePlan = useFreePlan()
	const isProfissionalPlan =
		user?.subscription?.product_name === "Profissional"
	const containerRef = useRef<HTMLDivElement>(null)
	const creditsInfoRef = useRef<HTMLDivElement>(null)
	const spaceRef = useRef<HTMLDivElement>(null)

	const handleMouseEnter = () => {
		setIsHovered(true)
	}

	const handleMouseLeave = (event: React.MouseEvent) => {
		if (
			containerRef.current &&
			creditsInfoRef.current &&
			spaceRef.current &&
			!containerRef.current.contains(event.relatedTarget as Node) &&
			!creditsInfoRef.current.contains(event.relatedTarget as Node) &&
			!spaceRef.current.contains(event.relatedTarget as Node)
		) {
			setIsHovered(false)
		}
	}

	const extraCredits = user?.extra_credits ? user?.extra_credits.toFixed() : 0

	return (
		<div
			ref={containerRef}
			className="w-full relative"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div
				className="h-4 w-full"
				ref={spaceRef}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			/>
			<CreditsInfo
				show={isHovered}
				productName={productName}
				user={user}
				containerRef={creditsInfoRef}
				isFreePlan={isFreePlan}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				isProfissionalPlan={isProfissionalPlan}
			/>
			<div className={`rounded-lg bg-gray-100 p-[16px] w-full`}>
				<div className={`flex justify-between`}>
					<div className={`flex items-center`}>
						<CreditIcon size="20px" />
						<p className="text-brand-gray-3 ml-1">Créditos</p>
					</div>
					<p className={`font-bold text-cta-1 ml-1 text-dark-blue-1`}>
						{user?.credits_remaining || user?.extra_credits
							? Math.ceil(user?.credits_remaining) +
							  Math.ceil(Number(extraCredits))
							: 0}
					</p>
				</div>
			</div>
		</div>
	)
}

export default NewCredit
