import { useQuery } from "@tanstack/react-query"
import { useJwt } from "./useJwt"
import useUserAPI from "./useUserAPI"
import { CurrentUser } from "types/currentUser"

export default function useCurrentUser() {
	const { currentUser } = useUserAPI()
	const [jwt] = useJwt()
	const { data: user } = useQuery(["current-user", jwt], currentUser)
	return user as CurrentUser
}
