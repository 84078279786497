import useCurrentUser from "./useCurrentUser"

export default function useCheckIfHasCredits() {
	const user = useCurrentUser()

	const userExtraCredits = user?.extra_credits || 0
	const userCredits = user?.credits_remaining || 0

	if (user && (userExtraCredits > 0 || userCredits > 0)) {
		return true
	}

	return false
}
