import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import useCurrentUser from "hooks/useCurrentUser"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import UploadIcon from "icons/UploadIcon"
import { useAtom } from "jotai"
import { plansModalAtom } from "pages/BasePage/atoms"
import FileCard from "pages/DocumentPage/subcomponents/FileCard"
import { useEffect, useRef, useState } from "react"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"

export interface FileState {
	id: string
	name: string
	size: number
}

export default function FileDropzone({
	data,
	documentId,
}: {
	data: RetrieveLegalDocumentResponse
	documentId: string
}) {
	const [files, setFiles] = useState<FileState[]>([])
	const [startUploadIndexes, setStartUploadIndexes] = useState<number[]>([])
	const [errorFiles, setErrorFiles] = useState<FileState[]>([])
	const [uploadHover, setUploadHover] = useState(false)
	const inputFileRef = useRef<HTMLInputElement>(null)
	const user = useCurrentUser()
	const [, setIsOpenPlansModal] = useAtom(plansModalAtom)

	const queryClient = useQueryClient()
	const { uploadFileToDocument } = useLegalDocumentAPI()
	const uploadDocumentMutation = useMutation({
		mutationFn: uploadFileToDocument,
		onError: (error: AxiosError) => {
			if (error.response?.status === 400) {
				showToast(
					"Arquivo com excesso de texto. Por favor, tente outro.",
					ToastType.Error,
				)
			} else {
				showToast(
					"Um erro inesperado aconteceu. Por favor, tente novamente.",
					ToastType.Error,
				)
			}
		},
	})

	const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		if (user) {
			if (user.total_storage_usage >= user.subscription.max_upload_size) {
				if (user.subscription.product_name === "Profissional") {
					showToast(
						"Armazenamento cheio. Remova arquivos para liberar espaço de armazenamento.",
						ToastType.Error,
					)
				} else {
					showToast(
						"Armazenamento cheio. Faça upgrade ou remova arquivos para liberar espaço de armazenamento.",
						ToastType.Error,
					)
					setIsOpenPlansModal(true)
				}
				return
			}
		}

		const file = event.dataTransfer.files[0]

		if (file) {
			if (file && file.size > 15728640) {
				showToast(
					"O total dos arquivos anexados não pode exceder 15MB",
					ToastType.Error,
				)
				return
			}
			setFiles([
				...files,
				{
					name: file.name.split(".")[0],
					size: file.size,
					id: "",
				},
			])
			setStartUploadIndexes([...startUploadIndexes, files.length])
			const formData = new FormData()

			formData.append("file_path", file)

			uploadDocumentMutation
				.mutateAsync({ id: documentId, payload: formData })
				.then(() => {
					setStartUploadIndexes(startUploadIndexes)
					queryClient.invalidateQueries({
						queryKey: ["legalDocument", documentId],
					})
				})
				.catch(() => {
					setStartUploadIndexes(startUploadIndexes)
					setErrorFiles([
						...errorFiles,
						{
							id: "",
							name: file.name,
							size: file.size,
						},
					])
					setFiles(
						(prev) =>
							prev?.filter((_, ind) => ind !== files.length),
					)
				})
		}
	}
	function onDragOver(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault()
	}

	function onDragEnter(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault()
	}

	function onDragLeave(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault()
	}
	function handleDeleteFile(index: number) {
		setFiles(files?.filter((_, ind) => ind !== index))
	}
	function handleDivClick() {
		if (user) {
			if (user.total_storage_usage >= user.subscription.max_upload_size) {
				if (user.subscription.product_name === "Profissional") {
					showToast(
						"Armazenamento cheio. Remova arquivos para liberar espaço de armazenamento.",
						ToastType.Error,
					)
				} else {
					showToast(
						"Armazenamento cheio. Faça upgrade ou remova arquivos para liberar espaço de armazenamento.",
						ToastType.Error,
					)
					setIsOpenPlansModal(true)
				}
				return
			}
		}
		if (inputFileRef.current) {
			inputFileRef.current.click()
		}
	}
	const onFileChange = async (event: any) => {
		const filesData = files
			? [...files, ...event.target.files]
			: [...event.target.files]
		if (
			filesData &&
			filesData.reduce((a: number, b: File) => {
				return a + b.size
			}) > 15728640
		) {
			showToast(
				"O total dos arquivos anexados não pode exceder 15MB",
				ToastType.Error,
			)
			return
		}

		setFiles(
			filesData.map((file) => {
				return {
					...file,
					name: file.name.split(".")[0],
					size: file.size,
				}
			}),
		)
		setStartUploadIndexes([...startUploadIndexes, filesData.length - 1])
		const formData = new FormData()
		formData.append("file_path", filesData.at(-1))

		uploadDocumentMutation
			.mutateAsync({ id: documentId, payload: formData })
			.then(() => {
				setStartUploadIndexes(startUploadIndexes)
				queryClient.invalidateQueries({
					queryKey: ["legalDocument", documentId],
				})
			})
			.catch(() => {
				setFiles(files)
				setErrorFiles([...errorFiles, filesData.at(-1)])
				setStartUploadIndexes(startUploadIndexes)
			})
		event.target.value = ""
	}

	useEffect(() => {
		if (
			data &&
			data?.attached_documents &&
			data?.attached_documents?.length > 0
		) {
			setFiles(
				data?.attached_documents?.map((document) => {
					return {
						id: document.id,
						name: document.file_name,
						size: document.file_size,
					} as File & { id: string }
				}),
			)
		}
	}, [data?.attached_documents?.length])

	return (
		<div className="flex gap-4 w-full flex-wrap ">
			<div className="flex flex-col h-[200px] mb-6">
				<div
					className={`flex items-center flex-col bg-white border-2 rounded text-center shadow-outlined  border-dashed cursor-pointer  w-[174px] h-[152px]`}
					onClick={handleDivClick}
					onDragOver={onDragOver}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					onDrop={onDrop}
					onMouseEnter={() => {
						setUploadHover(true)
					}}
					onMouseLeave={() => {
						setUploadHover(false)
					}}
				>
					<div className="flex flex-col text-center items-center p-3.5 my-1 h-full hover:bg-semi-white-4">
						<div className="bg-semi-white-4 w-[32px] h-[32px] rounded-full mb-6">
							<UploadIcon />
						</div>

						<input
							type="file"
							className="hidden"
							onChange={onFileChange}
							ref={inputFileRef}
							accept=".pdf, .txt, .docx"
						></input>
						<p className="text-brand-gray-2 text-cta-2 w-[134px] leading-5">
							Clique ou arraste aqui para{" "}
							<span className="text-dark-blue-1 text-cta-2 leading-5">
								fazer upload
							</span>
						</p>
					</div>
				</div>

				{uploadHover && (
					<span className="text-center text-brand-gray-2 text-[12px] leading-5 mt-2">
						Anexe até 15MB em arquivos
					</span>
				)}
			</div>

			{files?.map((file, index) => {
				return (
					<FileCard
						key={index}
						documentId={documentId}
						error={false}
						file={file}
						isUploading={startUploadIndexes.includes(index)}
						onDelete={() => handleDeleteFile(index)}
					/>
				)
			})}
			{errorFiles?.map((file, index) => {
				return (
					<FileCard
						key={index}
						documentId={documentId}
						error
						file={file}
						isUploading={startUploadIndexes.includes(index)}
						onDelete={() => {
							setErrorFiles(
								errorFiles?.filter((_, ind) => ind !== index),
							)
						}}
					/>
				)
			})}
		</div>
	)
}
